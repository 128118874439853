import React, {Component} from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import TopBar from './components/TopBar';
import Profile from './components/Profile';
import Footer from './components/Footer';
import ProfileSummary from "./components/ProfileSummary";

class App extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    return (
      <BrowserRouter>
      <div className="App">
          <TopBar />
          <ProfileSummary />
        <Routes>
          {/* <TopBar/> */}
            {/* <Route exact path="/" element={<TopBar/>} > </Route>
            <Route exact path="/" element={<ProfileSummary />} > </Route> */}
          {/* <ProfileSummary/> */}
          
            <Route exact path="/" element={<Profile />} />

            <Route exact path="/home" element={<Profile />} />
          
            {/* <Route exact path='/topbar' element={<TopBar />} /> */}
          {/* <Route exact path='/about' element={<About />} /> */}
          
        </Routes>

        <Footer/>
      </div>
      </BrowserRouter>
    );
  }
}

export default App;
