import React from 'react';
import profile from '../profile.json';

function About() {

    return(
      <div>
        <br/>
        <br/>
        <p style={{ fontSize: '100%' }}>{profile.summary1.split('\n').map(c => {
          return (<p key={c}> {c} </p>)
        })
        }</p>
        <br />
        <br />
      </div>
    )
}

export default About;