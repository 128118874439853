import React from 'react';
import profile from '../profile.json';
import '../index.css';

class ProfileSummary extends React.Component {
  
  render() {
    return <div class='jumbotron'> 
        <h1 style={{ textAlign: "center"}} className="display-3">{profile.title}</h1>
        <p style={{textAlign:"center",fontSize : '100%'}}>{profile.summary.split('\n').map(c => {
          return (<span key={c}> {c} </span>)
        })
        }</p>
      </div>
  }
}

export default ProfileSummary;