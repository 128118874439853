import React from 'react';
import {Container} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub, faTwitter, faStrava} from '@fortawesome/free-brands-svg-icons';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { MDBContainer} from "mdbreact";
import Resume from '../resume.pdf';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

class Footer extends React.Component {
  render() {
    return (
      <footer>
        {/* <a href="mailto:faiyaz.sundrani@gmail.com"><FontAwesomeIcon icon={faEnvelope} /></a> */}
        <Container>
          <a href="mailto:faiyaz.sundrani@gmail.com"><FontAwesomeIcon icon={faEnvelope} /></a>
          <a href="https://www.linkedin.com/in/faiyazsundrani/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} /></a>
          <a href="https://github.com/faiyazs" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faGithub}  /></a>
          <a href="https://twitter.com/faiyazsundrani" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} /></a>
          <a href="https://www.strava.com/athletes/faiyazz" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faStrava} /></a>
          <a href={Resume} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFileDownload} /></a>
        </Container>
        <div className="footer-copyright text-center py-3">
          <MDBContainer fluid>
            &copy; {new Date().getFullYear()} Copyright: Faiyaz Sundrani
            <br/>
            New York, USA.
          </MDBContainer>
        </div>
      </footer>
    )
  }
}

export default Footer;